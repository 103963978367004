






















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalTeacherAdd extends Mixins(ModalBaseMethod) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private teacherDatas: { nickname: '' }[] = [{ nickname: '' }]

  private isProcessing = false

  @Prop()
  title!: string

  @Prop()
  subjectTitle!: string

  /**
   * バリデーションエラー
   */
  private get nameErrors(): { index: number; error: string }[] {
    const errors = [] as { index: number; error: string }[]
    for (let i = 0; i < this.teacherDatas.length; i++) {
      if (this.teacherDatas[i].nickname !== '') {
        if (this.teacherDatas[i].nickname.length > 20) {
          errors.push({ index: i, error: `${this.subjectTitle}は20文字までです` })
        }
      }
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.teacherDatas.length > 0 &&
      this.teacherDatas.filter((teacher) => teacher.nickname === '').length === 0 &&
      this.nameErrors.length === 0

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private addRow(): void {
    this.teacherDatas.push({ nickname: '' })
  }

  private removeRow(index: number): void {
    this.teacherDatas.splice(index, 1)
  }

  /**
   * 教師追加リクエスト
   */
  private async createTeachers() {
    if (this.isProcessing) return
    this.isProcessing = true
    await Vue.prototype.$http.httpWithToken
      .post('/teachers', { branchId: this.branchId, teachers: this.teacherDatas })
      .then(() => {
        alert('登録が完了しました。')

        this.teacherDatas = [{ nickname: '' }]
        this.$emit('hide-model')
        this.hide()
      })
      .finally(() => {
        this.isProcessing = false
      })
  }
}
