

























import { Component, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalTeacherAdd from '@/components/organisms/ModalTeacherAdd.vue'
import ModalUserNameEdit from '@/components/organisms/ModalUserNameEdit.vue'
import ModalConfirmGdlsCode from '@/components/organisms/ModalConfirmGdlsCode.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

export type TeacherResponse = {
  teacherId: number
  teacherCode: string
  userId: number
  nickname: string
  gdlsCode: string
  isGidRegistered: boolean
}

export type TeacherTableParams = {
  code: string
  name: { name: string; onclick: Function; variable?: any }
  gdlsCode: { name: string; onclick: Function; variable?: any }[]
  reissue: { name: string; onclick: Function; variable?: any }[]
  student: { name: string; onclick: Function; variable?: any }[]
  gid: string
  delete: { name: string; onclick: Function; variable?: any }[]
}

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    TableBase,
    ModalTeacherAdd,
    ModalUserNameEdit,
    ModalConfirmGdlsCode,
  },
})
export default class Teacher extends Vue {
  @Ref() modalAddTeacher!: ModalTeacherAdd
  @Ref() modalEditTeacher!: ModalUserNameEdit
  @Ref() modalConfirmGdls!: ModalConfirmGdlsCode

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '講師・メンター', active: true },
  ]

  private teachersCount = 0

  private currentPage = 1

  private tableBasePerPage = 30

  private tableBaseButtons = ['gdlsCode', 'reissue', 'student', 'delete']

  private tableBaseLinks = ['name']

  private tableBaseFields = [
    { key: 'code', label: '講師コード' },
    { key: 'name', label: '氏名' },
    { key: 'gdlsCode', label: 'GDLSコード' },
    { key: 'reissue', label: '再発行' },
    { key: 'student', label: '授業対象生徒' },
    { key: 'gid', label: 'G-ID' },
    { key: 'delete', label: '削除' },
  ]

  private tableBaseItems: TeacherTableParams[] = []

  private async mounted() {
    this.loadTeachers()
  }

  /**
   * 教師追加モーダル表示
   */
  private showTeacherAdd(): void {
    this.modalAddTeacher.show()
  }

  /**
   * 教師名変更モーダル表示
   */
  private showTeacherNameEdit(user: { id: number; nickname: string }): void {
    this.modalEditTeacher.showWithUser(user.id, user.nickname)
  }

  /**
   * GDLSコード確認モーダル表示
   */
  private showGdlsCode(user: { nickname: string; gdlsCode: string }): void {
    this.modalConfirmGdls.setManager(user.nickname, user.gdlsCode)
  }

  /**
   * 教師一覧取得
   */
  private async loadTeachers() {
    const params = [`limit=${this.tableBasePerPage}`, `offset=${this.tableBasePerPage * (this.currentPage - 1)}`]
    params.push(`branchId=${Vue.prototype.$cookies.get('dataGdls').branchId}`)

    await Vue.prototype.$http.httpWithToken.get(`/teachers?${params.join('&')}`).then((res: any) => {
      const teachers: TeacherResponse[] = res.data.teachers
      this.tableBaseItems = teachers.map((teacher) => {
        return this.tableParams(teacher)
      })
      this.teachersCount = res.data.count
    })
  }

  /**
   * Table用パラメータ返却
   */
  private tableParams(teacher: TeacherResponse): TeacherTableParams {
    return {
      code: teacher.teacherCode,
      name: {
        name: teacher.nickname,
        onclick: this.showTeacherNameEdit,
        variable: { id: teacher.userId, nickname: teacher.nickname },
      },
      gdlsCode: [
        {
          name: '確認',
          onclick: this.showGdlsCode,
          variable: { nickname: teacher.nickname, gdlsCode: teacher.gdlsCode },
        },
      ],
      reissue: [{ name: '再発行', onclick: this.reissueGdls, variable: teacher.userId }],
      student: [{ name: '設定', onclick: this.moveToStudentCharge, variable: teacher.teacherId }],
      gid: teacher.isGidRegistered ? '済' : '未',
      delete: [{ name: '削除', onclick: this.destroyUser, variable: teacher.userId }],
    }
  }

  /**
   * GDLSコード再発行
   */
  private async reissueGdls(id: number) {
    if (!confirm('再発行しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .post(`/users/${id}/gdlsCode`)
      .then(() => {
        alert('GDLSコード再発行が完了しました。')
        this.loadTeachers()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * 教師を削除する
   */
  private async destroyUser(id: number) {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/users/${id}`)
      .then(() => {
        this.loadTeachers()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * 授業対象生徒画面へ遷移
   */
  private moveToStudentCharge(teacherId: number) {
    this.$router.push({ name: 'StudentCharge', params: { teacherId: String(teacherId) } })
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadTeachers()
  }
}
